import AbHandler from '/components/AbHandler';
import AddToCart from '/components/products/show/product-form/AddToCart';
import AlternateSubscriptionSelector from '/components/products/show/product-form/AlternateSubscriptionSelector';
import BackInStock from '/components/products/show/product-form/BackInStock';
import Button from '/components/Button';
import Clickable from '/components/Clickable';
import CountryFunctionality from '../../CountryFunctionality';
import CustomersAlsoBoughtTwo from '/components/CustomersAlsoBoughtTwo';
import ExtraFormInfo from '/components/ExtraFormInfo';
import FreeKoozieMessaging from '/components/temporary/FreeKoozieMessaging';
import GiftOptions from '/components/GiftOptions';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import LaundryRadios from '/components/products/show/product-form/LaundryRadios';
import ModalShopPay from '/components/products/show/product-form/ModalShopPay';
import ModalSubscriptions from '/components/products/show/product-form/ModalSubscriptions';
import Price from '../../Price';
import ProductDescription from '/components/products/show/product-form/ProductDescription';
import ProductOptionsPackedStandard from '/components/products/show/product-form/ProductOptionsPackedStandard';
import ProductOptionsPacksPrebuilt from '/components/products/show/product-form/ProductOptionsPacksPrebuilt';
import ProductOptionsStandard from '/components/products/show/product-form/ProductOptionsStandard';
import ProductTitle from '/components/products/show/ProductTitle';
import PropTypes from 'prop-types';
import PurchaseOptions from '/components/products/show/product-form/PurchaseOptions';
import QuantitySelector from '/components/products/mystery-pack/QuantitySelector';
import RadioGroup from '/components/RadioGroup';
import SubscriptionPrice from '/components/discount-codes/SubscriptionPrice';
import Typography from '/components/Typography';
import { VALID_SITEWIDE_PACK_TYPES } from '/services/static/packs/build-a-pack';
import classNames from '/utils/class-names';
import { findProductSellingPlan } from '/services/static/subscription-v2/find-product-selling-plan';
import { generateProductOptionSettings } from '/components/products/show/product-form/util-product-option-settings';
import { generateSubscriptionSettings } from '/components/products/show/product-form/util-subscription-settings';
import { generateVariantDataLookup } from '/components/products/show/product-form/util-variant-data-lookup';
import { getAddToCartDataForSubscription } from '/components/products/show/product-form/util-add-to-cart-data-subscription';
import { getAddToCartDataStandard } from '/components/products/show/product-form/util-add-to-cart-data-standard';
import { getCompleteProduct } from '/services/products';
import { getFromPrice } from '/utils/cart-processor';
import { getNewPackProductTypes } from '/services/packs';
import { getSubscriptionAnalyticsProductFromSellingPlan } from '/services/static/subscription-v2/auxillary-selling-plan-data';
import { getTestVariant } from '/services/ab-test';
import { logTryCatch } from '/utils/logging';
import { observer } from 'mobx-react-lite';
import { trackProductView } from '/services/analytics/shinesty-analytics';
import { updateWunderkindProductSubscriptionTracking } from '/services/analytics/wunderkind';
import { useCartStore } from '/state/cart-context';
import { useDiscountCodeStore } from '/state/discount-code-context';
import { useGlobalStore } from '/state/global-context';
import { CutSelector, mensCuts, womensCuts } from '/components/products/show/CutSelector';
import {
	PRODUCT_TYPE_BRALETTES_TRIANGLE,
	PRODUCT_TYPE_LOUNGE_MENS_HOODIE,
	PRODUCT_TYPE_LOUNGE_MENS_JOGGER,
	PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE,
	PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER,
	PRODUCT_TYPE_SLEEP_MENS_BOTTOMS,
	PRODUCT_TYPE_SLEEP_MENS_TOPS,
	PRODUCT_TYPE_SLEEP_WOMENS_DRESS,
	PRODUCT_TYPE_SLEEP_WOMENS_SETS,
} from '../../../services/static/product-types';
import { PrintSelector, PrintSelectorBuckets } from '/components/products/show/PrintSelector';
import { checkProductTags, findProductTag } from '/utils/product-tags';
import { useEffect, useState } from 'react';

const ProductForm = ({
	addToCartCallback,
	freeProduct,
	hasSwappedVariants,
	hideCrossShoppable = false,
	isQuickShop = false,
	joinedProducts = [],
	location,
	packProducts = [],
	pdpConfig,
	product,
	setActiveProductHandle,
	showExtraFormInfo = true,
	showProductDetails = true,
	showAnnualOption = false,
	subOnlyPdp = false,
	showProductLink = false,
	vueProperties,
}) => {
	const discountCodeStore = useDiscountCodeStore();
	const globalStore = useGlobalStore();
	const cartStore = useCartStore();
	const [activeHandle, setActiveHandle] = useState();
	const newPackProductTypes = getNewPackProductTypes();
	const promotion = globalStore.getPromotionalConfig();
	const [giftAttributes, setGiftAttributes] = useState(null);

	// state of subscription
	const defaultSubscriptionSettings = generateSubscriptionSettings(
		product,
		showAnnualOption,
		subOnlyPdp,
		globalStore.currentCountry?.countryCode,
		promotion,
	);

	const [cartType, setCartType] = useState(
		defaultSubscriptionSettings?.defaultCartType || 'product',
	);

	const [sellingPlan, setSellingPlan] = useState(defaultSubscriptionSettings.defaultSellingPlan);
	const [showSubscription, setShowSubscription] = useState(true);

	//ab for cuts
	const [showCuts, setShowCuts] = useState(false);
	// ab for sticky cart
	const [showStickyCart, setShowStickyCart] = useState(false);

	const [subscriptionAlternateProduct, setSubscriptionAlternateProduct] = useState(null);
	const [subscriptionSize, setSubscriptionSize] = useState(null);
	const [subscriptionSettings, setSubscriptionSettings] = useState(defaultSubscriptionSettings);
	const [subscriptionRadios, setSubscriptionRadios] = useState(subscriptionSettings.radios);

	// state of modal
	const [modalSubscriptionOpen, setModalSubscriptionOpen] = useState(false);
	const [modalShopPayOpen, setModalShopPayOpen] = useState(false);
	const [primaryVariant, setPrimaryVariant] = useState();
	const [quantity, setQuantity] = useState(1);
	// state of customer interactions
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [addToCartAttempted, setAddToCartAttempted] = useState(false);
	const [addToCartError, setAddToCartError] = useState(false);
	const [addToCartLoading, setAddToCartLoading] = useState(false);
	const [notSatisfiedError, setNotSatisfiedError] = useState(false);

	// state of back in stock
	const [backInStockSettings, setBackInStockSettings] = useState({ show: false });

	// constants used in rendering below
	const isCooling = product.productType.includes('Cooling');
	const isMixedPack = product.productType === 'Mixed Packs';

	// TODO: Temporary workaround for the criquet collab product. Shelly working with pipeline to figure out a better solution.
	const isOldUnderwearPack =
		checkProductTags(product.tags, 'Underwear Packs') &&
		!newPackProductTypes.includes(product.productType) &&
		product.handle !== 'criquet-collaboration-ball-hammock-pouch-underwear-with-fly-3-pack';

	const preBuiltPackType = findProductTag(product.tags, 'prebuilt', 1);
	const productOptionSettings = generateProductOptionSettings(joinedProducts, packProducts);

	const styleNumber = findProductTag(product.tags, 'style', 1);

	// monitor selected options and update the ui
	useEffect(() => {
		const showError = addToCartAttempted && !isSelectionSatisfied(product, packProducts);
		setNotSatisfiedError(showError);

		const primaryOption = selectedOptions.find((option) => {
			return option.primary === true;
		});

		if (primaryOption) {
			setSubscriptionSize(primaryOption.variantTitle);
			setPrimaryVariant(primaryOption.variantId);
		}

		// this enables us to show the subscription options before a variant is selected
		if (subscriptionSettings.isSubscriptionAvailable) {
			setShowSubscription(true);
		}

		// hide the subscription options if the size is not available for subscription
		if (primaryOption?.variantTitle && subscriptionSettings.validSizes) {
			const validSizeIndex = subscriptionSettings.validSizes.findIndex((size) => {
				return size.toLowerCase() === primaryOption.variantTitle.toLowerCase();
			});

			if (validSizeIndex === -1) {
				handleCartTypeChange('product');
				const currentRadios = subscriptionRadios.filter(
					(radio) => !radio?.value?.includes('selling-plan'),
				);
				setSubscriptionRadios(currentRadios);
			} else {
				setSubscriptionRadios(subscriptionSettings.radios);
			}
		} else {
			setSubscriptionRadios(subscriptionSettings.radios);
		}
	}, [selectedOptions, subscriptionSettings]);

	useEffect(() => {
		setSelectedOptions([]);
		setActiveHandle(product.handle);
		const fetchTestVariant = async () => {
			const testVariant2 = await getTestVariant('sticky-atc-2025');
			setShowCuts(
				product?.productType?.includes('Shorts') || product?.productType?.includes('Laundry'),
			);
			setShowStickyCart(testVariant2.key === 'sticky');
		};
		fetchTestVariant();
	}, [product.handle]);

	// monitor the current country and swap out the subscription settings if necessary
	useEffect(() => {
		const discountCodeConfig = discountCodeStore.getCurrentDiscountCodeConfig(product?.productType);

		const firstMonthPrice = discountCodeConfig?.subscriptionPrice;

		const newSubscriptionSettings = generateSubscriptionSettings(
			product,
			showAnnualOption,
			subOnlyPdp,
			globalStore.currentCountry?.countryCode,
			promotion,
			firstMonthPrice,
		);

		setSubscriptionSettings(newSubscriptionSettings);
		setCartType(newSubscriptionSettings?.defaultCartType || 'product');
		setSellingPlan(newSubscriptionSettings.defaultSellingPlan);
	}, [globalStore.currentCountry, product?.productType]);

	const sizeOption = product.options.find((option) => {
		return option.name === 'Size';
	});

	const hasSingleSizeOption = (sizeOption && sizeOption.values.length <= 1) || pdpConfig.isLaundry;

	let viewDetailsClass =
		'border-2 flex items-center justify-center py-3 px-6 uppercase tracking-widest font-bold text-primary';

	if (isCooling) {
		viewDetailsClass += ' border-secondary-darker text-secondary-darker';
	} else {
		viewDetailsClass += ' border-primary';
	}

	const productFlavor = findProductTag(product.tags, 'flavor', 1, 'surprise');

	joinedProducts = joinedProducts.map((product) => {
		return getFromPrice(product);
	});

	/**
	 * looks at the selected variants and adds them to the cart.
	 */
	const addItemsToCart = async () => {
		setAddToCartAttempted(true);

		if (!isSelectionSatisfied(product, packProducts)) {
			setNotSatisfiedError(true);
			return;
		}

		setNotSatisfiedError(false);
		setAddToCartLoading(true);
		setAddToCartError(false);

		// the fake "none" button has "none" as a variant
		const variantIds = selectedOptions.reduce((memo, selectedOption) => {
			if (
				selectedOption.variantId &&
				selectedOption.variantId !== 'none' &&
				selectedOption.disabled !== true
			) {
				memo.push(selectedOption.variantId);
			}
			return memo;
		}, []);

		if (variantIds.length === 0) {
			return;
		}

		const variantDataLookup = generateVariantDataLookup(
			product,
			joinedProducts,
			packProducts,
			quantity,
		);

		let addToCartData;

		if (cartType === 'alternate-subscription') {
			const shopifyProduct = await getCompleteProduct(subscriptionAlternateProduct.handle);

			addToCartData = getAddToCartDataForSubscription(
				shopifyProduct.product,
				subscriptionAlternateProduct,
				variantDataLookup,
				variantIds[0],
				vueProperties,
				shopifyProduct.product.sellingPlans.find((plan) => {
					return plan.key === 'monthly';
				}),
				discountCodeStore,
			);
		} else if (cartType?.includes('selling-plan') && sellingPlan) {
			addToCartData = getAddToCartDataForSubscription(
				product,
				subscriptionAlternateProduct,
				variantDataLookup,
				variantIds[0],
				vueProperties,
				sellingPlan,
				discountCodeStore,
			);
		} else {
			addToCartData = getAddToCartDataStandard(
				freeProduct,
				hasSwappedVariants,
				isMixedPack,
				isOldUnderwearPack,
				packProducts,
				preBuiltPackType,
				product,
				selectedOptions,
				variantDataLookup,
				variantIds,
				vueProperties,
				sitewidePackEnable,
			);
		}

		if (!addToCartData || !addToCartData.lines || addToCartData.lines.length === 0) {
			setAddToCartLoading(false);
			setAddToCartError(true);
			return;
		}

		// Add gift attributes to each line item if present
		if (giftAttributes && giftAttributes.length > 0) {
			addToCartData.lines.forEach((line) => {
				if (!line.attributes) {
					line.attributes = [];
				}
				line.attributes = [...line.attributes, ...giftAttributes];
			});
		}

		await cartStore
			.addItems({
				analytics: addToCartData.analytics,
				analyticsProduct: addToCartData.analyticsProduct,
				lines: addToCartData.lines,
				error: () => {
					setAddToCartLoading(false);
					setAddToCartError(true);
				},
				success: () => {
					setAddToCartLoading(false);
					setAddToCartError(false);

					if (addToCartCallback) {
						addToCartCallback(addToCartData.lines);
					}
				},
			})
			.catch((e) => {
				console.log(e);
				setAddToCartLoading(false);
				setAddToCartError(true);
				logTryCatch(e);
			});
	};

	const handleCartTypeChange = (radioValue) => {
		// subscription options
		if (radioValue == 'alternate-subscription') {
			setCartType('subscribe-new-plans');
		}

		if (radioValue.includes('selling-plan-')) {
			const parsed = radioValue.replace('selling-plan-', '');
			const selectedSellingPlan = findProductSellingPlan(product.sellingPlans, parsed);
			setSellingPlan(selectedSellingPlan);
			setCartType(radioValue);

			const analyticsProduct = getSubscriptionAnalyticsProductFromSellingPlan(
				product,
				sellingPlan,
				subscriptionSettings.alternateSubscriptionProductType || product.productType,
				'single',
			);

			if (analyticsProduct) {
				updateWunderkindProductSubscriptionTracking(analyticsProduct.id);
				analyticsProduct.id = product.id;
				trackProductView([analyticsProduct], false, analyticsProduct.prices[0]);
			}

			return;
		}
		// if (cartType == 'subscribe-annually') {
		// 	const analyticsProduct = getSubscriptionAnalyticsData(
		// 		'annually',
		// 		product,
		// 		subscriptionSettings.alternateSubscriptionProductType || product.productType,
		// 		'single',
		// 	);

		// 	if (analyticsProduct) {
		// 		updateWunderkindProductSubscriptionTracking(analyticsProduct.id);
		// 		analyticsProduct.id = product.id;
		// 		trackProductView({ products: [analyticsProduct] });
		// 	}

		// 	return;
		// }

		trackProductView([product], false, product.prices[0]);
		setCartType(radioValue);
		// if (!radioValue.includes('selling-plan-')) {
		// 	setSellingPlan();
		// }
	};

	/**
	 * @param {object} product the product object from shopify
	 * @param {arrray} packProducts the list of pack products associated iwth this product
	 * @returns takes the product and ensures an option is selected
	 */
	const isSelectionSatisfied = (product, packProducts) => {
		// pack products, unlike joined products, need all selections to be met
		if (!isOldUnderwearPack && !preBuiltPackType && packProducts && packProducts.length > 0) {
			return packProducts.every((packProduct) => {
				return selectedOptions.find((selectedOption) => {
					return (
						selectedOption.value !== 'none' &&
						selectedOption.productId === packProduct.id &&
						selectedOption.disabled !== true
					);
				});
			});
		}

		// ensure all the options for the product are met
		// typically this is "1" -- an option (size) matches the selected options (size)
		const selectedProductOptions = selectedOptions.filter((selectedOption) => {
			return (
				selectedOption.disabled !== true &&
				selectedOption.value !== 'none' &&
				selectedOption.productId === product.id
			);
		});

		return selectedProductOptions.length >= 1;
	};

	const hasCompareAtPrice =
		product.compareAtPrices.length > 0 && product.compareAtPrices[0] !== product.prices[0];

	const showShopByPrintCut =
		(womensCuts.includes(product.productType) || mensCuts.includes(product.productType)) &&
		!isCooling &&
		!product.productType.includes('Shorts') &&
		!hideCrossShoppable;

	const showShopByPrint =
		!hideCrossShoppable &&
		[
			'Boxers - Cooling - Fly',
			'Long Johns',
			"Men's Shorts",
			"Men's T-Shirts",
			PRODUCT_TYPE_LOUNGE_MENS_JOGGER,
			PRODUCT_TYPE_BRALETTES_TRIANGLE,
			PRODUCT_TYPE_LOUNGE_MENS_HOODIE,
			PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE,
			PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER,
			PRODUCT_TYPE_SLEEP_MENS_TOPS,
			PRODUCT_TYPE_SLEEP_MENS_BOTTOMS,
			PRODUCT_TYPE_SLEEP_WOMENS_DRESS,
			PRODUCT_TYPE_SLEEP_WOMENS_SETS,
		].includes(product.productType);

	const updateQuantity = (event) => {
		let newQuantity = event.target.value;

		if (typeof newQuantity === 'string') {
			try {
				newQuantity = Number.parseInt(newQuantity);
			} catch (e) {
				logTryCatch('could not parse new quantity');
			}
		}
		setQuantity(newQuantity);
	};

	const incrementQuantity = (delta) => {
		const newQuantity = quantity + delta;

		if (newQuantity < 1) {
			return;
		}

		setQuantity(newQuantity);
	};
	if (product.tags.includes('no-purchase') || product.prices[0] === 0) {
		return (
			<>
				<div className="text-center">Sorry, this product is not available for purchase.</div>
				{!isQuickShop && <CustomersAlsoBoughtTwo product={product} />}
			</>
		);
	}

	const packDiscount = cartStore.getNextPackDiscount();
	const packPrice = product.prices[0] * (packDiscount.discountPercentInverse || 1);

	const sitewidePackEnable =
		VALID_SITEWIDE_PACK_TYPES.includes(product.productType) && globalStore.getSitewidePack();

	//need to move this to a component
	const showSockPriceBadge =
		product?.productType?.includes('Sock') &&
		!product?.productType?.includes('Pack') &&
		cartType === 'product';

	const showSubscribeMessaging =
		checkProductTags(product.tags, 'Show Subscribe Option') &&
		!(product.productType.includes('Cooling') && hasCompareAtPrice);

	const priceInt = parseInt(product?.prices[0]);

	const handleGiftOptionsChange = (attributes) => {
		setGiftAttributes(attributes);
	};

	console.log('product', subscriptionRadios);
	return (
		<div>
			<form>
				{location !== 'Shorts Landing Page' && (
					<div className="hidden md:block">
						<ProductTitle product={product} />
					</div>
				)}
				<div className="mb-6 pl-1 flex flex-wrap items-center gap-2">
					{productOptionSettings.hasJoinedProducts && <Typography variant="body">from</Typography>}

					<div className="flex items-center gap-2">
						{hasCompareAtPrice && (
							<Typography
								aria-hidden="true"
								className="italic line-through text-gray-500"
								variant="body">
								<Price price={product.compareAtPrices[0]} selectedVariant={primaryVariant} />
							</Typography>
						)}

						<Typography variant="body">
							<Price
								format={'explicit'}
								price={product.prices[0]}
								product={product}
								selectedVariant={primaryVariant}
							/>
						</Typography>

						{sitewidePackEnable && packPrice != product.prices[0] && (
							<Typography className="text-primary" variant="body">
								<Price format={'explicit'} price={packPrice} />
							</Typography>
						)}
					</div>

					{showSockPriceBadge && (
						<div className="bg-primary text-white rounded-lg px-3 py-1">
							<Typography variant="body">
								Get 3 socks for{' '}
								<Price format={'explicit'} price={30} selectedVariant={primaryVariant} />
							</Typography>
						</div>
					)}

					{showSubscribeMessaging && subscriptionSettings.compareAtPrice && (
						<CountryFunctionality functionalityKey="subscription">
							<div className="flex flex-col">
								<Typography className="text-primary flex items-center" variant="body">
									(
									<SubscriptionPrice
										priceDefaultSubscription={subscriptionSettings.compareAtPrice}
										productType={product?.productType}
									/>
									<span className="pl-1">on subscription)</span>
								</Typography>
								{promotion && promotion?.promotionType == 'subscription-discount' && (
									<Typography variant="body">
										Renews at {subscriptionSettings.compareAtPrice}
									</Typography>
								)}
							</div>
						</CountryFunctionality>
					)}
				</div>
				{sitewidePackEnable && (
					<div className="mb-4 text-center">
						<Typography variant="subhead-xs" className="text-[#2200F5]">
							The more you buy, the more you save
						</Typography>
						<div className="flex items-center align-middle justify-center">
							<Icon name="sparkle" height="16px" width="16px" />
							<Typography className="ml-1" variant="body">
								Hint: Mix &amp; match as many underwear &amp; save up to 33%
							</Typography>
						</div>
					</div>
				)}

				{showShopByPrintCut && (
					<>
						<PrintSelectorBuckets
							product={product}
							setActiveProductHandle={setActiveProductHandle}
							size={subscriptionSize}
						/>
						{showCuts && (
							<CutSelector product={product} setActiveProductHandle={setActiveProductHandle} />
						)}
					</>
				)}
				{pdpConfig.isLaundry && (
					<CutSelector product={product} setActiveProductHandle={setActiveProductHandle} />
				)}

				{showShopByPrint && (
					<>
						<PrintSelector product={product} size={subscriptionSize} />
						{showCuts && (
							<CutSelector product={product} setActiveProductHandle={setActiveProductHandle} />
						)}
					</>
				)}

				{product.tags.includes('feature: Subscription Exclusive') && (
					<div>
						<Image
							alt="subscription exclusive"
							height={125}
							src="https://cdn.shopify.com/s/files/1/0234/5963/files/SubsExclusiveBanner_Prod-SM.png?v=1603399489"
							width={400}
						/>
					</div>
				)}

				{/* OptionsStandardProduct */}
				{!productOptionSettings.hasPackedProducts && (
					<ProductOptionsStandard
						activeHandle={activeHandle}
						hasJoinedProducts={productOptionSettings.hasJoinedProducts}
						hasSingleSizeOption={hasSingleSizeOption}
						joinedProducts={joinedProducts}
						product={product}
						selectedOptions={selectedOptions}
						setBackInStockSettings={setBackInStockSettings}
						setSelectedOptions={setSelectedOptions}
					/>
				)}

				{/* OptionsPackedProductsStandard */}
				{!isOldUnderwearPack && !preBuiltPackType && productOptionSettings.hasPackedProducts && (
					<ProductOptionsPackedStandard
						packProducts={packProducts}
						selectedOptions={selectedOptions}
						setBackInStockSettings={setBackInStockSettings}
						setSelectedOptions={setSelectedOptions}
						showProductType={productOptionSettings.numberOfPackedProducts !== 1}
					/>
				)}

				{/* OptionsPacks  */}
				{(isOldUnderwearPack || preBuiltPackType) && productOptionSettings.hasPackedProducts && (
					<ProductOptionsPacksPrebuilt
						product={product}
						selectedOptions={selectedOptions}
						setBackInStockSettings={setBackInStockSettings}
						setSelectedOptions={setSelectedOptions}
					/>
				)}

				{/* product type tag */}
				{/* {!showShopByPrint && <ProductType productType={product.productType} />} */}

				{/* subscription radio selector buttons */}
				{subscriptionSettings.isSubscriptionAvailable && showSubscription && (
					<>
						{pdpConfig.isLaundry && (
							<LaundryRadios
								cartType={cartType}
								name="add_to_cart_type"
								radios={subscriptionRadios}
								onChange={handleCartTypeChange}
								sellingPlan={sellingPlan}
							/>
						)}

						{!pdpConfig.isLaundry && (
							<>
								<AbHandler
									control={
										<RadioGroup
											checkedValue={cartType}
											name="add_to_cart_type"
											radios={subscriptionRadios}
											onChange={handleCartTypeChange}
										/>
									}
									emptyHeight={'200px'}
									testId="purchase-options"
									variations={{
										'new-purchase-options': (
											<PurchaseOptions
												className="border-2 border-v2-brown-darker rounded-md p-4"
												checkedValue={cartType}
												openMembershipModal={() => setModalSubscriptionOpen(true)}
												name="add_to_cart_type"
												radios={subscriptionRadios}
												onChange={handleCartTypeChange}
											/>
										),
									}}
								/>
							</>
						)}
					</>
				)}
				{cartType === 'product' && (
					<QuantitySelector
						currentQuantity={quantity}
						incrementQuantity={incrementQuantity}
						minimumQuantity={1}
						updateQuantity={updateQuantity}
					/>
				)}
				{/* alternate product subscription selector */}
				{subscriptionSettings.isAlternateSubscripton && showSubscription && (
					<AlternateSubscriptionSelector
						alternateSubscriptionCollection={subscriptionSettings.alternateSubscriptionCollection}
						cartType={cartType}
						flavor={productFlavor}
						product={product}
						setModalSubscriptionOpen={setModalSubscriptionOpen}
						setSubscriptionAlternateProduct={setSubscriptionAlternateProduct}
						size={subscriptionSize}
						subscriptionSettings={subscriptionSettings}
					/>
				)}
				{product?.handle?.includes('surprise') && (
					<div className="text-center font-semibold">
						<Typography variant="body">All mystery underwear sales are final.</Typography>
					</div>
				)}
				{(!backInStockSettings.show || backInStockSettings.overrideShow) && (
					<>
						<GiftOptions product={product} onGiftOptionsChange={handleGiftOptionsChange} />
						<div
							className={classNames(
								selectedOptions.length >= 1 &&
									showStickyCart &&
									'sticky bottom-3 bg-v2-off-white md:relative',
							)}>
							<AddToCart
								addItemsToCart={addItemsToCart}
								addToCartLoading={addToCartLoading}
								alternateBackground={isCooling ? 'bg-secondary-darker' : ''}
								cartType={cartType}
								location={location}
								selectedOptions={selectedOptions}
								showAddToCartError={addToCartError}
								showNotSatisfiedError={notSatisfiedError}
								product={product}
							/>
						</div>
					</>
				)}

				{showSubscribeMessaging && (
					<CountryFunctionality functionalityKey="subscription">
						<div className="text-center pb-3">
							<Button
								className="text-v2-brown-darker underline underline-offset-2"
								onClick={() => setModalSubscriptionOpen(true)}
								title="How Membership Works"
								variant="link">
								<Typography variant="body">How Membership Works</Typography>
							</Button>
						</div>
					</CountryFunctionality>
				)}
			</form>

			{/* Back in stock app */}
			{backInStockSettings.show && <BackInStock backInStockSettings={backInStockSettings} />}

			{/* Banners we show beneath the form */}
			{showExtraFormInfo && <ExtraFormInfo product={product}></ExtraFormInfo>}

			{!isQuickShop && priceInt > 50 && (
				<div className="my-4 text-center">
					<div className="text-center">
						<Typography variant="small" className="text-v2-orange-burnt">
							Pay in full or in 4 interest-free installments of {priceInt / 4} with{' '}
							<div className=" align-text-top inline-flex ml-1 w-[66px]">
								<Image
									src="https://cdn.shinesty.com/2024-05-24/AP_logo_badge_6328x2204_whiteblack_png.png"
									height={70}
									width={200}
									alt="afterpay logo"
									objectFit="contain"
								/>
							</div>
						</Typography>
						<Button
							variant="link"
							title="Learn More"
							className="capitalize hover:text-v2-orange-burnt text-v2-orange-burnt underline underline-offset-2 ml-2"
							onClick={() => setModalShopPayOpen(true)}>
							Learn More
						</Button>
					</div>
				</div>
			)}

			{/* election product temporary */}
			{['gid://shopify/Product/7401834479685', 'gid://shopify/Product/7401834545221'].includes(
				product.id,
			) && <FreeKoozieMessaging />}

			{!isQuickShop && pdpConfig.showCrossSell && (
				<CustomersAlsoBoughtTwo
					header={<Typography variant="heading-sm">Customers Also Bought</Typography>}
					product={product}
				/>
			)}

			{!showProductLink && (
				<ProductDescription
					pdpConfig={pdpConfig}
					productTags={product.tags}
					showProductDetails={showProductDetails && !isCooling}
					styleNumber={styleNumber}
					text={product.description}
				/>
			)}
			{showProductLink && (
				<Clickable
					className={viewDetailsClass}
					linkToInternal={`/products/${product.handle}`}
					heapEventName="Quick Shop View Full Details"
					heapEventData={{
						product: product.handle,
					}}>
					View Full Details
				</Clickable>
			)}

			{/* Modals */}
			<ModalSubscriptions
				modalSubscriptionOpen={modalSubscriptionOpen}
				product={product}
				setModalSubscriptionOpen={setModalSubscriptionOpen}
			/>
			<ModalShopPay modalShopPayOpen={modalShopPayOpen} setModalShopPayOpen={setModalShopPayOpen} />
		</div>
	);
};

ProductForm.propTypes = {
	addToCartCallback: PropTypes.func,
	completeTheLookProducts: PropTypes.object,
	freeProduct: PropTypes.object,
	hasSwappedVariants: PropTypes.bool,
	hideCrossShoppable: PropTypes.bool,
	isQuickShop: PropTypes.bool,
	joinedProducts: PropTypes.array,
	location: PropTypes.string,
	packBox: PropTypes.object,
	packProducts: PropTypes.array,
	pdpConfig: PropTypes.object,
	product: PropTypes.object.isRequired,
	setActiveProductHandle: PropTypes.func,
	showAnnualOption: PropTypes.bool,
	showExtraFormInfo: PropTypes.bool,
	showProductDetails: PropTypes.bool,
	showProductLink: PropTypes.bool,
	subOnlyPdp: PropTypes.bool,
	vueProperties: PropTypes.object,
	widgetInfo: PropTypes.object,
};

export default observer(ProductForm);
