import Image from '/components/Image';
import PropTypes from 'prop-types';
import SizeGuide from '/components/size-guides/SizeGuide';
import Typography from '/components/Typography';
import { useState } from 'react';

const ExpandableSection = ({ title, textComponent, content, isOpen, onToggle }) => {
	return (
		<div className="border-b border-v2-brown mb-4 p-4">
			<button className="flex justify-between w-full text-left" onClick={onToggle}>
				<Typography variant="heading-sm" component={textComponent || 'h2'}>
					{title}
				</Typography>
				<span>{isOpen ? '-' : '+'}</span>
			</button>
			{isOpen && <div className="mt-4">{content}</div>}
		</div>
	);
};

const ExpandableDetails = ({
	pdpConfig,
	productDescription,
	productDetails,
	shippingInfo,
	styleNumber,
}) => {
	const [activeTab, setActiveTab] = useState(0);

	const detailsSection = (
		<div>
			{productDetails}
			<SizeGuide styleNumber={styleNumber} />
		</div>
	);

	const guaranteeSection = (
		<p>
			Love it or it&apos;s free. If this item doesn&apos;t live up to the hype, your first one is
			100% on us (seriously, we&apos;ll just refund you). Terms apply.
		</p>
	);

	const descriptionSection = <p>{productDescription}</p>;
	const sections = [
		{
			title: 'Details',
			content: descriptionSection,
		},
		{
			title: 'Fit & Fabric',
			content: detailsSection,
		},
		{
			title: 'Shipping & Returns',
			content: shippingInfo,
		},
	];
	if (pdpConfig?.showPromise) {
		sections.unshift({
			title: 'Promise',
			content: guaranteeSection,
		});
	}
	return (
		<div className="">
			<div className="grid grid-cols-2 gap-1 my-4">
				{pdpConfig?.pdpIcons?.map((icon, i) => {
					return (
						<div
							key={i}
							className="flex flex-col text-center gap-2 items-center align-middle justify-center px-5">
							<Image src={icon.image} alt={icon.text} height={50} width={50} objectFit="cover" />

							<Typography variant="micro">{icon.text}</Typography>
						</div>
					);
				})}
			</div>

			<div className="border-b border-v2-brown">
				<div className="flex justify-between">
					{sections.map((section, index) => (
						<button
							key={index}
							className={`py-2 ${
								activeTab === index
									? 'border-b-2 border-v2-brown-darker font-bold text-v2-brown-darker px-2'
									: 'text-v2-brown'
							}`}
							onClick={() => setActiveTab(index)}>
							<Typography
								variant="body"
								component={pdpConfig.detailsComponent || 'h4'}
								className="hidden md:block">
								{section.title}
							</Typography>
							<Typography
								variant="micro"
								component={pdpConfig.detailsComponent || 'h4'}
								className="md:hidden">
								{section.title}
							</Typography>
						</button>
					))}
				</div>
			</div>

			<div className="py-4">{sections[activeTab].content}</div>
		</div>
	);
};

export default ExpandableDetails;

ExpandableSection.propTypes = {
	content: PropTypes.any,
	isOpen: PropTypes.bool,
	onToggle: PropTypes.func,
	textComponent: PropTypes.string,
	title: PropTypes.string,
};

ExpandableDetails.propTypes = {
	pdpConfig: PropTypes.object,
	productDescription: PropTypes.string,
	productDetails: PropTypes.any,
	shippingInfo: PropTypes.any,
	styleNumber: PropTypes.string,
};
