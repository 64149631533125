import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

function PurchaseOptions({
	checkedValue,
	className = '',
	name,
	openMembershipModal,
	radios = [],
	onChange = () => {},
	error = false,
	errorText = '',
}) {
	const hasError = error || errorText;

	let finalClass = `${className} mb-6`;
	if (hasError) {
		finalClass += ' border-red-600';
	}

	return (
		<div className={finalClass}>
			<Typography variant="heading-sm">Purchase Options</Typography>
			<div className="flex flex-col gap-6 mt-2">
				{radios.map((radio) => {
					const isSubscription = radio.value.includes('selling-plan');
					const isChecked = checkedValue === radio.value;

					return (
						<div
							key={`${radio.value}-${name}`}
							className={classNames(
								'border rounded-lg cursor-pointer transition-all',
								isChecked ? 'border-2 border-primary' : 'border border-gray-300',
								isSubscription && isChecked ? 'bg-[#FFF5F0]' : '',
							)}
							onClick={() => onChange(radio.value)}>
							<div className="relative p-4">
								{isSubscription && (
									<div className="absolute top-0 left-4 -translate-y-1/2">
										<div className="bg-primary text-white px-4 py-1 rounded-lg">
											<Typography variant="small">Best Value</Typography>
										</div>
									</div>
								)}

								<div className="flex items-center">
									<div
										className={classNames(
											'border-2 rounded-full w-6 h-6 flex justify-center items-center mr-3 shrink-0',
											isChecked ? 'border-primary' : 'border-gray-300',
										)}>
										<input
											name={name}
											type="radio"
											checked={isChecked}
											className="rounded-full appearance-none w-4 h-4 outline-0 cursor-pointer checked:bg-primary"
											id={`${radio.value}-${name}`}
											onChange={() => onChange(radio.value)}
										/>
									</div>
									<div>{radio.label}</div>
								</div>

								{isSubscription && (
									<div className="mt-2 ml-9">
										<button
											className="flex items-center"
											onClick={(e) => {
												e.preventDefault();
												openMembershipModal();
											}}>
											<Typography variant="small" className="text-gray-600 underline">
												How membership works
											</Typography>
										</button>
									</div>
								)}
							</div>
						</div>
					);
				})}
			</div>

			{errorText && (
				<div className="bg-red-200 mt-2 py-2 px-4 text-xs text-primary rounded-sm">{errorText}</div>
			)}
		</div>
	);
}

PurchaseOptions.propTypes = {
	checkedValue: PropTypes.string,
	className: PropTypes.string,
	error: PropTypes.bool,
	errorText: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	openMembershipModal: PropTypes.func,
	radios: PropTypes.array.isRequired,
};

export default PurchaseOptions;
